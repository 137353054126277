import React, { useState } from "react";
import PropTypes from 'prop-types';
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic.jsx";
import InfiniteBoardComponent from "../widgets/infiniteBoardComponent/InfiniteBoardComponent.jsx"
import HelperMetiers360 from "../../services/HelpersMetiers360.js";

/**
 * 
 * @param {Array} professionals array of all professionals to be rendered
 * @example
 *  <ProfessionalsBoardComponent professionals={professionals} />
 */

const ProfessionalsBoardComponent = ({ professionals }) => {
    const [professionalsToDisplay, setProfessionalsToDisplay] = useState([]);
    professionals.sort((a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name));

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(professionals, professionalsToDisplay, setProfessionalsToDisplay, 10);

    return <InfiniteBoardComponent
        listOfMedia={professionalsToDisplay}
        mediaType={"professional"}
        isLink={true}
        fetchData={fetchData}
        hasMore={hasMore}
    />;
}

ProfessionalsBoardComponent.propTypes = {
    professionals: PropTypes.array.isRequired
};

export default ProfessionalsBoardComponent;