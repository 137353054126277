import React, { useEffect, useState } from "react";
import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import { useStoreState } from 'easy-peasy';
import { useLocation, useNavigate } from 'react-router-dom';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

/**
 * 
 * @param {Array} professionals array of all professionals to be rendered
 * @param {bool} withParams  bool to handle params in url
 * 
 * @example
 *  <ProfessionalsTableComponent professionals={professionals}/>
 * 
 */

const ProfessionalsTableComponent = ({ professionals = [], withParams }) => {
    const [professionalsToDisplay, setProfessionalsToDisplay] = useState([]);
    const [sortedProfessionals, setSortedProfessionals] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const { apiData: { BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);
    const { proTypes } = BACKOFFICE_DEFAULT_VALUES;

    const videosSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setShouldSort(true);
        setSortedProfessionals([...professionals]);
    }, [professionals]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedProfessionals, professionalsToDisplay, setProfessionalsToDisplay, 100);

    const handleClickVideo = (uniqueIdVideo) => {
        if (videosSlugs.readOne) {
            navigate(videosSlugs.readOne.replace(':uniqueId', uniqueIdVideo), { state: { olderSearchParams: location.search } });
        }
    }
    const displayVideo = (video) => <Button variant='light' size='sm' className="m-1"
        onClick={(e) => { e.stopPropagation(); handleClickVideo(video.uniqueId) }}>
        {video.videoName}
    </Button>;


    const sortType = [
        {
            value: 'name', label: 'Professionnel⋅le',
            test: HelperMetiers360.isArrayContainsValue(professionals, 'name'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (pro) => pro.name
        },
        {
            value: 'mainJob', label: 'Métiers',
            test: HelperMetiers360.isArrayContainsValue(professionals, 'mainJob'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.mainJob?.label, b.mainJob?.label),
            display: (pro) => (pro?.mainJob?.label ?? "")
                + (pro.jobAppellations?.length > 0
                    ? (pro.mainJob?.label ? ", " : "") + pro.jobAppellations?.map(job => job.label)?.join(", ")
                    : "")
                + (pro.proType ? (" (" + proTypes?.find(pt => pt.value == pro.proType)?.label + ")") : "")
        },
        {
            value: 'areasOfInterest', label: 'Centres d\'intérêt',
            test: HelperMetiers360.isArrayContainsValue(professionals, 'areasOfInterest'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.areasOfInterest[0]?.label, b.areasOfInterest[0]?.label),
            display: (pro) => pro.areasOfInterest?.map(area => area.label)?.join(", ")
        },
        {
            value: 'softSkills', label: 'Savoir-être professionnels',
            test: HelperMetiers360.isArrayContainsValue(professionals, 'softSkills'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.softSkills[0]?.label, b.softSkills[0]?.label),
            display: (pro) => pro.softSkills?.map(area => area.label)?.join(", ")
        },
        {
            value: 'workingConditions', label: 'Contextes de travail',
            test: HelperMetiers360.isArrayContainsValue(professionals, 'workingConditions'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.workingConditions[0]?.label, b.workingConditions[0]?.label),
            display: (pro) => pro.workingConditions?.map(condition => condition.label)?.join(", ")
        },
        {
            value: 'videos', label: 'Expérience immersive',
            test: HelperMetiers360.isArrayContainsValue(professionals, 'videos'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.videos[0]?.videoName, b.videos[0]?.videoName),
            display: (pro) => <>{
                pro?.videos?.map(video => <div key={pro.uniqueId + '_' + video.uniqueId}>{
                    displayVideo(video)
                }</div>)
            }</>,
            flatDisplay: (pro) => pro.videos.map((video) => video.videoName).join(", ")
        },
        {
            value: 'from', label: 'Origine',
            test: HelperMetiers360.isArrayContainsValue(professionals, "from"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.from, b.from),
            display: (pro) => {
                return <>
                    {pro.from?.includes('métier') &&
                        <span>&#128994;</span>}
                    {pro.from?.includes('compétence') &&
                        <span>&#128309;</span>} &&
                    {pro.from?.includes('appellation') &&
                            <span>&#128992;</span>}
                </>
            }
        }
    ];

    return professionals.length > 0
        ? <DynamicTableInfinite
            contentTable={professionalsToDisplay}
            contentSort={sortType}
            valueInitSort="name"
            index='uniqueId'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedProfessionals}
            sortedContent={sortedProfessionals}
            sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
            handleClick={null}
            filename="professionnel⋅les"
        />
        : <></>;
}

ProfessionalsTableComponent.propTypes = {
    professionals: PropTypes.array,
    withParams: PropTypes.bool.isRequired,
};

export default ProfessionalsTableComponent;